<template>
  <div class="news">
    <div v-for="(item, index) in items" :key="index" class="news__item">
      <router-link :to="`${pathLocale}/news/${item.id}`">
        <div class="news__title" v-html="item.title"></div>
        <div v-if="item.date" class="news__date">
          {{ d(new Date(item.date), "date") }}
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { datetimeFormats } from "@/utils/formats";

export default defineComponent({
  name: "NewsRelated",
  props: {
    items: Array,
  },
  setup() {
    const { t, d, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      datetimeFormats,
    });

    return { t, d, locale };
  },
});
</script>

<style scoped lang="scss">
.news {
  margin-top: val(32);
  &__item {
    margin-top: val(22);
    a {
      text-decoration: none !important;
      // &:hover {
      //   .news__title {
      //     text-decoration: underline;
      //   }
      // }
    }
  }
  &__title {
    font-size: val(18);
    line-height: 120%;
    color: #6f6f6e;
    margin-top: val(14);
    @include mobile {
      font-size: valM(24);
      margin-top: valM(14);
    }
  }
  &__date {
    font-size: val(15);
    line-height: 125%;
    color: #808080;
    margin-top: val(14);
    @include mobile {
      font-size: valM(15);
      margin-top: valM(14);
    }
  }
}
</style>
