<template>
  <div class="grid-column grid-column_span_5">
    <div v-if="isMobile" class="pm-18"></div>
    <div>
      <h2 class="h2 news-title upper" v-html="item.title"></h2>
      <div v-if="isMobile" class="pm-24"></div>
      <p v-if="itemDate" class="color-grey">
        {{ d(itemDate, "date") }}
        {{ d(itemDate, "time") }}
      </p>
    </div>
  </div>

  <div class="grid-column">
    <Share :title="item.title" :image="itemImage" v-if="!isMobile" />
  </div>
  <div class="grid-column grid-column_span_4">
    <Promo
      v-if="item.image && !resetSrc"
      class="promo_full_width_only_mobile"
      :src-pic="itemImage"
      :src-pic-mobile="itemImage"
    />
  </div>

  <div v-if="isMobile || (!isMobile && item.desc)" class="grid-column">
    <Share :title="item.title" :image="itemImage" v-if="isMobile" />
  </div>
  <div v-if="!isMobile && item.desc" class="grid-column grid-column_span_4">
    <div class="h2-1" v-html="item.desc"></div>
  </div>

  <div
    v-if="item.gallery && item.gallery.images && item.gallery.images.length"
    class="grid-column grid-column_row_3"
  ></div>
  <div
    v-if="item.gallery && item.gallery.images && item.gallery.images.length"
    class="grid-column grid-column_span_4 grid-column_row_3"
  >
    <div v-if="isMobile" class="pm-18"></div>
    <Gallery :pics="itemGalleryImagesNormalize" />
    <div v-if="isMobile" class="pm-24"></div>
  </div>

  <div class="grid-column"></div>
  <div class="grid-column grid-column_span_3">
    <div v-if="isMobile" class="pm-18"></div>
    <div class="content-textarea" v-html="item.body"></div>
  </div>
  <div class="grid-column">
    <div v-if="item.related && item.related.length" class="news-related">
      <div class="h3 upper">Related</div>
      <NewsRelated :items="item.related" />
    </div>
  </div>

  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column"></div>
  <div class="grid-column">
    <div class="content-pattern content-pattern_type_1"></div>
  </div>

  <News />
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { mapState } from "vuex";

import { datetimeFormats } from "@/utils/formats";
import { mediaUrl } from "@/utils";

import Promo from "@/components/Promo.vue";
import Share from "@/components/Share.vue";
import News from "@/components/News.vue";
import NewsRelated from "@/components/NewsRelated.vue";
import Gallery from "@/components/Gallery.vue";

export default defineComponent({
  name: "Home",
  components: {
    Promo,
    Share,
    News,
    NewsRelated,
    Gallery,
  },
  setup() {
    const { t, d, locale } = useI18n({
      inheritLocale: true,
      useScope: "local",
      datetimeFormats,
    });

    return { t, d, locale };
  },
  computed: {
    ...mapState(["news", "newsStatus"]),
    item() {
      const { id } = this.$route.params;
      if (this.newsStatus == "success") {
        const result = this.news.find((e) => e.id === +id);

        return result || {};
      }
      return {};
    },
    itemImage() {
      if (this.item.image && this.item.image.path) {
        return this.item.image.path;
      }
      return "";
    },
    itemDate() {
      if (this.item.date) {
        return new Date(this.item.date);
      }
      return null;
    },
    itemGalleryImagesNormalize() {
      if (this.item.gallery && this.item.gallery.images.length) {
        return this.item.gallery.images.map((e) => e.path);
      }
      return [];
    },
  },
  watch: {
    item(val) {
      if (
        this.newsStatus == "success" &&
        !val.title &&
        this.$route.name === "NewsItem"
      ) {
        this.$router.push({
          name: "News",
          params: this.paramsLocale,
        });
      }
    },
    $route() {
      this.resetSrc = true;
      setTimeout(() => {
        this.resetSrc = false;
      }, 0);
    },
  },
  data() {
    return {
      mediaUrl,
      resetSrc: false,
    };
  },
});
</script>

<style lang="scss" scoped>
@include mobile {
  .news-related {
    margin: 0 calc(#{valM(-24)} - 1px);
    margin-top: valM(54);
    width: 100vw;
    position: relative;
    background-color: #fff;
    padding: valM(14) valM(10 + 14);
  }
}
</style>

<i18n>
{
  "en": {
    "title_1": "QATARI-RUSSIAN CENTER FOR COOPERATION",
    "text_1": "Promoting multi-faceted and cross-sectoral bilateral relations, the Qatari-Russian Center for Cooperation works to&nbsp;sustainably grow the bilateral landscape between the two nations."
  },
  "ru": {
    "title_1": "QATARI-RUSSIAN CENTER FOR COOPERATION",
    "text_1": "Катарско-российский центр сотрудничества (QRCC) способствует устойчивому развитию отношений между двумя странами по&nbsp;различным направлениям двустороннего сотрудничества."
  }
}
</i18n>
